import axiosInstance from '~/plusgin/axios';

export const listTitleData = {
    researchData:{
        id:2,
        title:"科研基础技能",
        list:[
            { 
                id:300,
                classInfoId: 100,
                title: "国科金其他部分写作",
                startTime: "2024-01-20 14:29:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:国科金其他部分写作</h1>",
                lookCount: 11221,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/9c52a740-caa9-4005-9757-16f812e549ad_微信图片_20240830143238.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E5%9B%BD%E7%A7%91%E9%87%91%E5%85%B6%E4%BB%96%E9%83%A8%E5%88%86%E5%86%99%E4%BD%9C.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:国科金其他部分写作</h1>",
            },
            {
                id:301,
                classInfoId: 101,
                title: "国科金写作中的常见问题",
                startTime: "2024-01-22 19:29:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:国科金写作中的常见问题</h1>",
                lookCount: 6587,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/638744e5-d831-4fad-be34-de4a8042134d_微信图片_20240830143342.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E5%9B%BD%E7%A7%91%E9%87%91%E5%86%99%E4%BD%9C%E4%B8%AD%E7%9A%84%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:国科金写作中的常见问题</h1>",
            },
            {
                id:302,
                classInfoId: 102,
                title: "国科金申请，评审流程和写作格式简介",
                startTime: "2024-02-26 19:29:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:国科金申请，评审流程和写作格式简介</h1>",
                lookCount: 24352,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/ea9b3d56-b775-48a4-986f-589a7bbdbaa8_微信图片_20240830143550.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E5%9B%BD%E7%A7%91%E9%87%91%E7%94%B3%E8%AF%B7%EF%BC%8C%E8%AF%84%E5%AE%A1%E6%B5%81%E7%A8%8B%E5%92%8C%E5%86%99%E4%BD%9C%E6%A0%BC%E5%BC%8F%E7%AE%80%E4%BB%8B.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:国科金申请，评审流程和写作格式简介</h1>",
            },
            {
                id:303,
                classInfoId: 103,
                title: "国科金写作中的文献阅读与理解",
                startTime: "2024-02-27 18:29:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:国科金写作中的文献阅读与理解</h1>",
                lookCount: 6232,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/2b9ac81a-1349-4064-9933-3f19d2b77c3e_微信图片_20240830143742.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E5%9B%BD%E7%A7%91%E9%87%91%E5%86%99%E4%BD%9C%E4%B8%AD%E7%9A%84%E6%96%87%E7%8C%AE%E9%98%85%E8%AF%BB%E4%B8%8E%E7%90%86%E8%A7%A3.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:国科金写作中的文献阅读与理解</h1>",
            },
            {
                id:304,
                classInfoId: 104,
                title: "技术路线图绘制",
                startTime: "2024-03-01 18:34:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:技术路线图绘制</h1>1",
                lookCount: 7656,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/3e934244-c856-4e6a-92ec-9a339f9397f1_微信图片_20240830143757.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E6%8A%80%E6%9C%AF%E8%B7%AF%E7%BA%BF%E5%9B%BE%E7%BB%98%E5%88%B6.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:技术路线图绘制</h1>",
            },
            {
                id:305,
                classInfoId: 105,
                title: "近年来国科金申报结果和研究热点分析",
                startTime: "2024-03-03 18:34:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:近年来国科金申报结果和研究热点分析</h1>1",
                lookCount: 4132,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/dcbc7edc-f228-4cb9-8361-2eda55616b7e_微信图片_20240830144249.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E8%BF%91%E5%B9%B4%E6%9D%A5%E5%9B%BD%E7%A7%91%E9%87%91%E7%94%B3%E6%8A%A5%E7%BB%93%E6%9E%9C%E5%92%8C%E7%A0%94%E7%A9%B6%E7%83%AD%E7%82%B9%E5%88%86%E6%9E%90.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:近年来国科金申报结果和研究热点分析</h1>",
            },
            {
                id:306,
                classInfoId: 106,
                title: "立项依据和预实验部分写作",
                startTime: "2024-03-06 20:34:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:立项依据和预实验部分写作</h1>1",
                lookCount: 4321,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/cb8f0238-02ce-4bf4-ab58-49d726fbd759_微信图片_20240830144352.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E7%AB%8B%E9%A1%B9%E4%BE%9D%E6%8D%AE%E5%92%8C%E9%A2%84%E5%AE%9E%E9%AA%8C%E9%83%A8%E5%88%86%E5%86%99%E4%BD%9C.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:立项依据和预实验部分写作</h1>",
            },
            {
                id:307,
                classInfoId: 107,
                title: "零思路如何设计科研课题：筛选目的基因",
                startTime: "2024-03-10 20:34:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:零思路如何设计科研课题：筛选目的基因</h1>1",
                lookCount: 41821,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/85a4c716-c5ad-4723-921e-2352e7371ad5_微信图片_20240830144554.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E9%9B%B6%E6%80%9D%E8%B7%AF%E5%A6%82%E4%BD%95%E8%AE%BE%E8%AE%A1%E7%A7%91%E7%A0%94%E8%AF%BE%E9%A2%98%EF%BC%9A%E7%AD%9B%E9%80%89%E7%9B%AE%E7%9A%84%E5%9F%BA%E5%9B%A0.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:零思路如何设计科研课题：筛选目的基因</h1>",
            },
            {
                id:308,
                classInfoId: 108,
                title: "零思路如何设计科研课题：制定研究框架",
                startTime: "2024-03-20 20:34:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:零思路如何设计科研课题：制定研究框架</h1>1",
                lookCount: 42141,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/d9c5874a-d3e6-4e6b-8c42-e50875e2000e_微信图片_20240830144557.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E5%9B%BD%E8%87%AA%E7%84%B6%E7%83%AD%E7%82%B9/%E9%9B%B6%E6%80%9D%E8%B7%AF%E5%A6%82%E4%BD%95%E8%AE%BE%E8%AE%A1%E7%A7%91%E7%A0%94%E8%AF%BE%E9%A2%98%EF%BC%9A%E5%88%B6%E5%AE%9A%E7%A0%94%E7%A9%B6%E6%A1%86%E6%9E%B6.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:零思路如何设计科研课题：制定研究框架</h1>",
            },
            {
                id:309,
                classInfoId: 109,
                title: "2025年国自然哪些研究热点最具潜力.",
                startTime: "2024-04-06 20:34:14",
                applicationFalse: false,
                teacher: "李老师,沈老师,黎老师",
                subscribeCount: 110,
                classDesc: "李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力</h1>",
                lookCount: 143,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/e7773a9d-400a-4981-a1ca-597e83e96747_微信图片_20240830151343.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E4%BA%94%E4%B8%80%E5%BA%A7%E8%B0%88/2025%E5%B9%B4%E5%9B%BD%E8%87%AA%E7%84%B6%E5%93%AA%E4%BA%9B%E7%A0%94%E7%A9%B6%E7%83%AD%E7%82%B9%E6%9C%80%E5%85%B7%E6%BD%9C%E5%8A%9B.mp4",
                type: "video",
                teacherDesc: "<h1>李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力</h1>",
            },
            {
                id:310,
                classInfoId: 110,
                title: "动物实验专题分享会之动物造模失败的原因",
                startTime: "2024-09-26 14:29:14",
                applicationFalse: false,
                teacher: "郁老师,黄老师,许老师",
                subscribeCount: 110,
                classDesc: "郁老师,黄老师,许老师:动物实验专题分享会之动物造模失败的原因",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>郁老师,黄老师,许老师:动物实验专题分享会之动物造模失败的原因</h1>1",
                lookCount: 5155,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/b48e3c6f-7936-4c33-a4eb-f7a19c397f3a_微信图片_20240830151637.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E4%BA%94%E4%B8%80%E5%BA%A7%E8%B0%88/%E7%9B%B4%E6%92%AD%E5%9B%9E%E6%94%BE-04%E6%9C%8826%E6%97%A5.mp4",
                type: "video",
                teacherDesc: "<h1>郁老师,黄老师,许老师:动物实验专题分享会之动物造模失败的原因</h1>",
            },
            { 
                id:311,
                classInfoId: 111,
                title: "3月4日细胞增殖实验和克隆形成",
                startTime: "2024-03-04 15:30:20",
                applicationFalse: false,
                teacher: "许老师",
                subscribeCount: 110,
                classDesc: "许老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>许老师</h1>1",
                lookCount: 4125,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/e42f86f2-7751-40d0-a408-6edcf015982f_微信图片_20240830111732.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E8%AE%B8%E8%80%81%E5%B8%88/3%E6%9C%884%E6%97%A5%E7%BB%86%E8%83%9E%E5%A2%9E%E6%AE%96%E5%AE%9E%E9%AA%8C%E5%92%8C%E5%85%8B%E9%9A%86%E5%BD%A2%E6%88%90.mp4",
                type: "video",
                teacherDesc: "<h1>许老师:3月4日细胞增殖实验和克隆形成</h1>",
            },
            {
                id:312,
                classInfoId: 112,
                title: "实验技术指导Transwell与细胞划痕",
                startTime: "2024-01-16 15:30:23",
                applicationFalse: false,
                teacher: "许老师",
                subscribeCount: 110,
                classDesc: "许老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>许老师:1月16日 实验技术指导Transwell与细胞划痕.</h1>",
                lookCount: 31231,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/4e26b9ab-9cc8-46cb-b1ac-b1a5c96b0316_微信图片_20240830133928.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E8%AE%B8%E8%80%81%E5%B8%88/1%E6%9C%8816%E6%97%A5%20%E5%AE%9E%E9%AA%8C%E6%8A%80%E6%9C%AF%E6%8C%87%E5%AF%BCTranswell%E4%B8%8E%E7%BB%86%E8%83%9E%E5%88%92%E7%97%95.mp4",
                type: "video",
                teacherDesc: "<h1>许老师:1月16日 实验技术指导Transwell与细胞划痕.</h1>",
            },
        ]
    },
    clinicalData:{
        id:3,
        title:"生物医学研究",
        list:[
            {
                id:313,
                classInfoId: 113,
                title: "肿瘤学基础知识与临床研究数据解读方法系列课 第1节",
                startTime: "2024-02-08 14:29:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/2月28日 肿瘤学基础知识与临床研究数据解读方法系列课 第1节</h1>",
                lookCount: 8563,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/18a97dbc-6e60-4514-be7b-41392974fabd_20240830104819.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/2%E6%9C%8828%E6%97%A5%20%E8%82%BF%E7%98%A4%E5%AD%A6%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E4%B8%8E%E4%B8%B4%E5%BA%8A%E7%A0%94%E7%A9%B6%E6%95%B0%E6%8D%AE%E8%A7%A3%E8%AF%BB%E6%96%B9%E6%B3%95%E7%B3%BB%E5%88%97%E8%AF%BE%20%E7%AC%AC1%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "<h1>JZ369 唐辉/2月28日 肿瘤学基础知识与临床研究数据解读方法系列课 第1节</h1>",
            },
            {
                id:314,
                classInfoId: 114,
                title: "肿瘤学基础知识与临床研究数据解读方法系列课",
                startTime: "2024-03-06 18:30:00",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/3月6日 肿瘤学基础知识与临床研究数据解读方法系列课</h1>",
                lookCount: 3231,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/c2a617cb-f2e1-4fd9-a0e6-00f86f108ebb_微信图片_20240831093620.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/3%E6%9C%886%E6%97%A5%20%E8%82%BF%E7%98%A4%E5%AD%A6%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E4%B8%8E%E4%B8%B4%E5%BA%8A%E7%A0%94%E7%A9%B6%E6%95%B0%E6%8D%AE%E8%A7%A3%E8%AF%BB%E6%96%B9%E6%B3%95%E7%B3%BB%E5%88%97%E8%AF%BE.mp4",
                type: "video",
                teacherDesc: "<h1>JZ369 唐辉/3月6日 肿瘤学基础知识与临床研究数据解读方法系列课</h1>",
            },
            {
                id:315,
                classInfoId: 115,
                title: "肿瘤学基础知识与临床研究数据解读方法",
                startTime: "2024-04-22 18:30:00",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/4月22日 肿瘤学基础知识与临床研究数据解读方法</h1>",
                lookCount: 31231,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/e034e279-0c0a-40bf-b230-673ab7fd268c_微信图片_20240831084223.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/4%E6%9C%8822%E6%97%A5%20%E8%82%BF%E7%98%A4%E5%AD%A6%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E4%B8%8E%E4%B8%B4%E5%BA%8A%E7%A0%94%E7%A9%B6%E6%95%B0%E6%8D%AE%E8%A7%A3%E8%AF%BB%E6%96%B9%E6%B3%95.mp4",
                type: "video",
                teacherDesc: "<h1>JZ369 唐辉/4月22日 肿瘤学基础知识与临床研究数据解读方法</h1>",
            },
            {
                id:316,
                classInfoId: 116,
                title: "5月14日 第五节",
                startTime: "2024-05-14 14:29:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 1102,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/5月14日 第五节</h1>1",
                lookCount: 5415,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/4a5e5687-da7c-448f-b268-873d288971d6_微信图片_20240830110023.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/5%E6%9C%8814%E6%97%A5%20%E7%AC%AC%E4%BA%94%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "JZ369 唐辉/5月14日 第五节",
            },
            {
                id:317,
                classInfoId: 117,
                title: "第七节",
                startTime: "2024-05-18 19:30:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 1102,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/第七节</h1>1",
                lookCount: 1102,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/3f35ed97-74c0-4b1f-9980-664705258369_微信图片_20240831084706.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/%E7%AC%AC%E4%B8%83%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "JZ369 唐辉/第七节",
            },
            {
                id:318,
                classInfoId: 118,
                title: "肿瘤学基础知识与临床研究数据解读方法",
                startTime: "2024-05-22 14:29:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/4月22日 肿瘤学基础知识与临床研究数据解读方法</h1>1",
                lookCount: 54323,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/2628ecf8-0624-447e-9a31-f08d6a8fa1c7_微信图片_20240830105816.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/4%E6%9C%8822%E6%97%A5%20%E8%82%BF%E7%98%A4%E5%AD%A6%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E4%B8%8E%E4%B8%B4%E5%BA%8A%E7%A0%94%E7%A9%B6%E6%95%B0%E6%8D%AE%E8%A7%A3%E8%AF%BB%E6%96%B9%E6%B3%95.mp4",
                type: "video",
                teacherDesc: "JZ369 唐辉/4月22日 肿瘤学基础知识与临床研究数据解读方法",
            },
            {
                id:319,
                classInfoId: 119,
                title: "肿瘤学基础知识与临床研究数据解读方法第四节",
                startTime: "2024-06-01 14:29:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/ 肿瘤学基础知识与临床研究数据解读方法第四节</h1>1",
                lookCount: 42315,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/a8be1cb4-3bb8-4849-8b6e-8533267bb990_微信图片_20240830110409.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/%E7%AC%AC%E5%9B%9B%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "<h1>肿瘤学基础知识与临床研究数据解读方法第四节</h1>",
            },
            {
                id:320,
                classInfoId: 120,
                title: "肿瘤学基础知识与临床研究数据解读方法第五节",
                startTime: "2024-06-10 19:29:14",
                applicationFalse: false,
                teacher: "唐辉老师",
                subscribeCount: 110,
                classDesc: "唐辉老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ369 唐辉/ 肿瘤学基础知识与临床研究数据解读方法第五节</h1>1",
                lookCount: 6343,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/fa06dc6e-90b4-41c8-9f65-75af816462fb_微信图片_20240831084903.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ369%20%E5%94%90%E8%BE%89/%E8%82%BF%E7%98%A4%E5%AD%A6%E5%9F%BA%E7%A1%80%E7%9F%A5%E8%AF%86%E4%B8%8E%E4%B8%B4%E5%BA%8A%E7%A0%94%E7%A9%B6%E6%95%B0%E6%8D%AE%E8%A7%A3%E8%AF%BB%E6%96%B9%E6%B3%95%E7%AC%AC%E4%BA%94%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "<h1>>JZ369 唐辉/ 肿瘤学基础知识与临床研究数据解读方法第五节</h1>",
            },
        ]
    },
    biomedicineData:{
        id:4,
        title:"数据分析与工具",
        list:[
            { 
                id:321,
                classInfoId: 121,
                title: "R统计软件实操系列课第1节:初识R语言 ",
                startTime: "2024-01-04 18:29:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R统计软件实操系列课第1节:初识R语言</h1>",
                lookCount: 199,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/fdc8319c-ee20-4323-ae5f-108f6d34456a_微信图片_20240830153714.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/1%E6%9C%884%E6%97%A5%20R%E7%BB%9F%E8%AE%A1%E8%BD%AF%E4%BB%B6%E5%AE%9E%E6%93%8D%E7%B3%BB%E5%88%97%E8%AF%BE%E7%AC%AC1%E8%8A%82%EF%BC%9A%E5%88%9D%E8%AF%86R%E8%AF%AD%E8%A8%80%20.mp4",
                type: "video",
                teacherDesc: "<h1>林凯青老师:R统计软件实操系列课第1节:初识R语言</h1>",
            },
            { 
                id:322,
                classInfoId: 122,
                title: " R统计软件实操系列课(第2节)",
                startTime: "2024-01-18 19:29:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R统计软件实操系列课(第2节)</h1>1",
                lookCount: 4355,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/87b99497-bc97-4a57-ad81-1a763ed014f6_微信图片_20240830153744.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/1%E6%9C%8818%E6%97%A5%20R%E7%BB%9F%E8%AE%A1%E8%BD%AF%E4%BB%B6%E5%AE%9E%E6%93%8D%E7%B3%BB%E5%88%97%E8%AF%BE%EF%BC%88%E7%AC%AC2%E8%8A%82%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>林凯青老师:R统计软件实操系列课(第2节)</h1>1",
            },
            {
                id:323,
                classInfoId: 123,
                title: "R统计软件实操系列课R数据类型及运算（第3节）",
                startTime: "2024-01-25 18:29:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R统计软件实操系列课R数据类型及运算（第3节）",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R统计软件实操系列课R数据类型及运算（第3节）</h1>1",
                lookCount: 81,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/76a20e6f-c6e9-4d55-bf64-361edff63703_微信图片_20240830153808.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/1%E6%9C%8825%E6%97%A5%20R%E7%BB%9F%E8%AE%A1%E8%BD%AF%E4%BB%B6%E5%AE%9E%E6%93%8D%E7%B3%BB%E5%88%97%E8%AF%BER%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B%E5%8F%8A%E8%BF%90%E7%AE%97%EF%BC%88%E7%AC%AC3%E8%8A%82%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>林凯青老师:R统计软件实操系列课R数据类型及运算（第3节）</h1>1",
            },
            {
                id:324,
                classInfoId: 124,
                title: " R统计软件实操系列课（第4节）",
                startTime: "2024-02-01 19:30:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R统计软件实操系列课（第4节）",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R统计软件实操系列课（第4节</h1>1",
                lookCount: 2311,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/8cfa017a-0b45-4b1d-a3ef-534f6d88375c_微信图片_20240830154207.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/2%E6%9C%881%E5%8F%B7%20R%E7%BB%9F%E8%AE%A1%E8%BD%AF%E4%BB%B6%E5%AE%9E%E6%93%8D%E7%B3%BB%E5%88%97%E8%AF%BE%EF%BC%88%E7%AC%AC4%E8%8A%82%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R统计软件实操系列课（第4节",
            },
            {
                id:325,
                classInfoId: 125,
                title: "R统计软件实操系列课（五）",
                startTime: "2024-02-22 18:30:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R统计软件实操系列课（五）",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R统计软件实操系列课（五）</h1>1",
                lookCount: 1211,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/086e0d9f-ce84-485a-b208-74bd0e062ec9_微信图片_20240830154408.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/2%E6%9C%8822%E6%97%A5%20R%E7%BB%9F%E8%AE%A1%E8%BD%AF%E4%BB%B6%E5%AE%9E%E6%93%8D%E7%B3%BB%E5%88%97%E8%AF%BE%EF%BC%88%E4%BA%94%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R统计软件实操系列课（五）",
            },
            {
                id:326,
                classInfoId: 126,
                title: "R语言系列课程(六)",
                startTime: "2024-03-07 19:40:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R语言系列课程",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R语言系列课程</h1>1",
                lookCount: 3212,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/a4d0dc11-e510-4425-ba46-62a8c5e71cd4_微信图片_20240830154616.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/3-28%20Ryuy9.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R语言系列课程",
            },
            {
                id:327,
                classInfoId: 127,
                title: "R语言系列课程 第七讲",
                startTime: "2024-03-14 18:02:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R语言系列课程 第七讲",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R语言系列课程 第七讲</h1>1",
                lookCount: 5342,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/d17377ba-f53d-4cca-a189-05eaa9fea107_微信图片_20240830154750.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/3%E6%9C%8814%E6%97%A5%20R%E8%AF%AD%E8%A8%80%E7%B3%BB%E5%88%97%E8%AF%BE%E7%A8%8B%20%E7%AC%AC%E4%B8%83%E8%AE%B2.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R语言系列课程 第七讲",
            },
            {
                id:328,
                classInfoId: 128,
                title: "R语言系列课程 第八讲 数据汇总",
                startTime: "2024-03-21 18:04:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R语言系列课程 第八讲 数据汇总",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R语言系列课程 第八讲 数据汇总</h1>1",
                lookCount: 12432,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/2fd9121f-fe1a-4b0d-b95e-2455d957689a_微信图片_20240830154616.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/3%E6%9C%8821%E6%97%A5%20R%E8%AF%AD%E8%A8%80%E7%B3%BB%E5%88%97%E8%AF%BE%E7%A8%8B%20%E7%AC%AC%E5%85%AB%E8%AE%B2%20%E6%95%B0%E6%8D%AE%E6%B1%87%E6%80%BB.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R语言系列课程 第八讲 数据汇总",
            },
            {
                id:329,
                classInfoId: 129,
                title: "R语言第九讲",
                startTime: "2024-03-28 18:07:14",
                applicationFalse: false,
                teacher: "林凯青老师",
                subscribeCount: 110,
                classDesc: "林凯青老师:R语言第九讲",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>林凯青老师:R语言第九讲</h1>",
                lookCount: 1222321,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/ff1c81d7-6619-43ea-8d0e-d81b5e3a0876_微信图片_20240830154750.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ249%20%E6%9E%97%E5%87%AF%E9%9D%92/3-28%20Ryuy9.mp4",
                type: "video",
                teacherDesc: "林凯青老师:R语言第九讲",
            },
            {
                id:330,
                classInfoId: 130,
                title: "科研小白入门，让我们一起认识经典的endnote（上）",
                startTime: "2024-08-26 14:29:14",
                applicationFalse: false,
                teacher: "蔡金明老师",
                subscribeCount: 110,
                classDesc: "蔡金明老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>科研小白入门，让我们一起认识经典的endnote（上）</h1>",
                lookCount: 45342,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/02391079-7f72-4944-acc2-eca3695ecb18_微信图片_20240830134815.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ152%E8%94%A1%E9%87%91%E6%98%8EEndnote/s1.mp4",
                type: "video",
                teacherDesc: "<h1>JZ152蔡金明Endnote/科研小白入门，让我们一起认识经典的endnote（上</h1>",
            },
            {
                id:331,
                classInfoId: 131,
                title: "EndNote系列课 (第2节) 高级应用及扩展.mp4",
                startTime: "2024-08-26 14:29:14",
                applicationFalse: false,
                teacher: "蔡金明老师",
                subscribeCount: 110,
                classDesc: "蔡金明老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>JZ152蔡金明Endnote/2月6日 EndNote系列课（第2节） 高级应用及扩展.</h1>1",
                lookCount: 5332,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/785901d7-2984-4e73-b87c-98daa0c2e192_微信图片_20240830105341.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ152%E8%94%A1%E9%87%91%E6%98%8EEndnote/2%E6%9C%886%E6%97%A5%20EndNote%E7%B3%BB%E5%88%97%E8%AF%BE%EF%BC%88%E7%AC%AC2%E8%8A%82%EF%BC%89%20%E9%AB%98%E7%BA%A7%E5%BA%94%E7%94%A8%E5%8F%8A%E6%89%A9%E5%B1%95.mp4",
                type: "video",
                teacherDesc: "JZ152蔡金明Endnote/2月6日 EndNote系列课（第2节） 高级应用及扩展.", 
            },
        ]
    },
    projectPapersData:{
        id:5,
        title:"科研学术交流",
        list:[
            { 
                id:332,
                classInfoId: 132,
                title: "帕金森疾病模型的造模与特点（第1讲）",
                startTime: "2024-12-08 18:29:14",
                applicationFalse: false,
                teacher: "顾哲维老师",
                subscribeCount: 110,
                classDesc: "顾哲维老师:帕金森疾病模型的造模与特点（第1讲）",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>顾哲维老师:帕金森疾病模型的造模与特点（第1讲）</h1>",
                lookCount: 3221,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/4b0e6d1b-9679-4f7d-89b3-5f89d6aa3d11_微信图片_20240830172321.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ92%20%E9%A1%BE%E5%93%B2%E7%BB%B4/12%E6%9C%888%E6%97%A5%20%E5%B8%95%E9%87%91%E6%A3%AE%E7%96%BE%E7%97%85%E6%A8%A1%E5%9E%8B%E7%9A%84%E9%80%A0%E6%A8%A1%E4%B8%8E%E7%89%B9%E7%82%B9%EF%BC%88%E7%AC%AC1%E8%AE%B2%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>顾哲维老师:帕金森疾病模型的造模与特点（第1讲）</h1>",
            },
            {
                id:333,
                classInfoId: 133,
                title: "帕金森疾病模型的验证与常规治疗方法(第2讲)",
                startTime: "2024-12-15 19:30:14",
                applicationFalse: false,
                teacher: "顾哲维老师",
                subscribeCount: 110,
                classDesc: "顾哲维老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>顾哲维老师:帕金森疾病模型的验证与常规治疗方法(第2讲)</h1>",
                lookCount: 3465,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/f6321fef-b056-49b4-861a-6d5392b1a555_微信图片_20240830172324.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ92%20%E9%A1%BE%E5%93%B2%E7%BB%B4/12%E6%9C%8815%E6%97%A5%20%20%E5%B8%95%E9%87%91%E6%A3%AE%E7%96%BE%E7%97%85%E6%A8%A1%E5%9E%8B%E7%9A%84%E9%AA%8C%E8%AF%81%E4%B8%8E%E5%B8%B8%E8%A7%84%E6%B2%BB%E7%96%97%E6%96%B9%E6%B3%95%EF%BC%88%E7%AC%AC2%E8%AE%B2%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>顾哲维老师:帕金森疾病模型的验证与常规治疗方法(第2讲)</h1>",
            },
            {
                id:334,
                classInfoId: 134,
                title: "帕金森疾病的新兴治疗方案（第3讲）",
                startTime: "2024-02-26 19:29:14",
                applicationFalse: false,
                teacher: "顾哲维老师",
                subscribeCount: 110,
                classDesc: "顾哲维老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>顾哲维老师:帕金森疾病的新兴治疗方案（第3讲）介</h1>",
                lookCount: 2131,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/2dd58421-d791-42e3-9dfc-421cd38d85b2_微信图片_20240830172327.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ92%20%E9%A1%BE%E5%93%B2%E7%BB%B4/12%E6%9C%8821%E6%97%A5%E5%B8%95%E9%87%91%E6%A3%AE%E7%96%BE%E7%97%85%E7%9A%84%E6%96%B0%E5%85%B4%E6%B2%BB%E7%96%97%E6%96%B9%E6%A1%88%EF%BC%88%E7%AC%AC3%E8%AE%B2%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>顾哲维老师:帕金森疾病的新兴治疗方案（第3讲）</h1>",
            },
            {
                id:335,
                classInfoId: 135,
                title: "干细胞和外泌体的提取与鉴定(第4讲)",
                startTime: "2024-12-28 13:30:14",
                applicationFalse: false,
                teacher: "顾哲维老师",
                subscribeCount: 110,
                classDesc: "顾哲维老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>干细胞和外泌体的提取与鉴定(第4讲)</h1>1",
                lookCount: 1213,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/a4baa85f-9959-48d3-a357-596a10b8ced7_微信图片_20240830135223.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ92%20%E9%A1%BE%E5%93%B2%E7%BB%B4/12%E6%9C%8828%E6%97%A5%E5%B9%B2%E7%BB%86%E8%83%9E%E5%92%8C%E5%A4%96%E6%B3%8C%E4%BD%93%E7%9A%84%E6%8F%90%E5%8F%96%E4%B8%8E%E9%89%B4%E5%AE%9A%EF%BC%88%E7%AC%AC4%E8%AE%B2%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>顾哲维老师:干细胞和外泌体的提取与鉴定(第4讲)</h1>1",
            },
            {
                id:336,
                classInfoId: 136,
                title: " R单细胞测序分析实战全程（一）",
                startTime: "2024-01-10 18:20:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(1)</h1>",
                lookCount: 5223,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/a1b7522a-b461-4f19-aed4-d86bce0cb211_微信图片_20240830140653.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ293%20%E5%8F%B2%E8%8B%B1%E6%AD%A6Luke/1%E6%9C%8810%E6%97%A5%20R%E5%8D%95%E7%BB%86%E8%83%9E%E6%B5%8B%E5%BA%8F%E5%88%86%E6%9E%90%E5%AE%9E%E6%88%98%E5%85%A8%E7%A8%8B%EF%BC%88%E4%B8%80%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(1)</h1>",
            },
            {
                id:337,
                classInfoId: 137,
                title: " 单细胞测序分析实战（二）",
                startTime: "2024-01-23 18:20:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(2)</h1>",
                lookCount: 5355,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/5e8d2ff1-605e-4d6b-93cb-85bdbc6b4c1e_微信图片_20240830142650.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ293%20%E5%8F%B2%E8%8B%B1%E6%AD%A6Luke/1%E6%9C%8823%E6%97%A5%20%E5%8D%95%E7%BB%86%E8%83%9E%E6%B5%8B%E5%BA%8F%E5%88%86%E6%9E%90%E5%AE%9E%E6%88%98%EF%BC%88%E4%BA%8C%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(2)</h1>",
            },
            {
                id:338,
                classInfoId: 138,
                title: " R单细胞测序分析实战全程（三）",
                startTime: "2024-01-30 18:20:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(3)</h1>",
                lookCount: 54323,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/dba31edc-f263-422c-b350-6612c02ec67c_微信图片_20240830142740.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ293%20%E5%8F%B2%E8%8B%B1%E6%AD%A6Luke/1%E6%9C%8830%E6%97%A5%20%E5%8D%95%E7%BB%86%E8%83%9E%E6%B5%8B%E5%BA%8F%E5%88%86%E6%9E%90%E5%AE%9E%E6%88%98%EF%BC%88%E4%B8%89%EF%BC%89.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:R单细胞测序分析实战全程(3)</h1>",
            },
            {
                id:339,
                classInfoId: 139,
                title: "单细胞测序分析实战第四节",
                startTime: "2024-02-02 18:20:14",
                applicationFalse: false,
                teacher: "史英武Luke老师",
                subscribeCount: 110,
                classDesc: "史英武Luke老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>史英武Luke老师:单细胞测序分析实战第四节</h1>",
                lookCount: 5623,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/12c97c1d-c3db-4a6a-a742-04d536106e0e_微信图片_20240830142816.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/JZ293%20%E5%8F%B2%E8%8B%B1%E6%AD%A6Luke/2%E6%9C%8820%E6%97%A5%20%E5%8D%95%E7%BB%86%E8%83%9E%E6%B5%8B%E5%BA%8F%E5%88%86%E6%9E%90%E5%AE%9E%E6%88%98%E7%AC%AC%E5%9B%9B%E8%8A%82.mp4",
                type: "video",
                teacherDesc: "<h1>史英武Luke老师:单细胞测序分析实战第四节</h1>",
            },
            {
                id:340,
                classInfoId: 140,
                title: "聚焦基因编辑技术",
                startTime: "2024-02-26 14:29:14",
                applicationFalse: false,
                teacher: "黎老师",
                subscribeCount: 110,
                classDesc: "黎老师",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>黎老师:聚焦基因编辑技术</h1>1",
                lookCount: 231123,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/cb401d58-79cb-4687-833f-9668bd3e66ec_微信图片_20240830155504.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/V326/%E8%81%9A%E7%84%A6%E5%9F%BA%E5%9B%A0%E7%BC%96%E8%BE%91%E6%8A%80%E6%9C%AF.mp4",
                type: "video",
                teacherDesc: "<h1>黎老师:聚焦基因编辑技术</h1>",
            },
            {
                id:341,
                classInfoId: 141,
                title: "2025年国自然哪些研究热点最具潜力.",
                startTime: "2024-04-06 20:34:14",
                applicationFalse: false,
                teacher: "李老师,沈老师,黎老师",
                subscribeCount: 110,
                classDesc: "李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力",
                categoryId: 1,
                status: 1,
                videoDesc: "<h1>李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力</h1>",
                lookCount: 52345,
                firm: "广东中科嘉迪生物医疗科技有限公司",
                cover: "https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/e7773a9d-400a-4981-a1ca-597e83e96747_微信图片_20240830151343.png",
                url:"https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/video/%E4%BA%94%E4%B8%80%E5%BA%A7%E8%B0%88/2025%E5%B9%B4%E5%9B%BD%E8%87%AA%E7%84%B6%E5%93%AA%E4%BA%9B%E7%A0%94%E7%A9%B6%E7%83%AD%E7%82%B9%E6%9C%80%E5%85%B7%E6%BD%9C%E5%8A%9B.mp4",
                type: "video",
                teacherDesc: "<h1>李老师,沈老师,黎老师:2025年国自然哪些研究热点最具潜力</h1>",
            },
        ]
    },
}


// 预处理数据，创建索引
const createIndex = (data) => {
    const index = new Map();
    Object.values(data).forEach(category => {
        category.list.forEach(item => {
            index.set(item.id, item);
        });
    });
    return index;
};

// 初始化索引
const indexMap = createIndex(listTitleData);


function processList(listData) {
    for (const key in listData) {
        if (listData.hasOwnProperty(key)) {
            const subObject = listData[key];
            if (subObject.list && Array.isArray(subObject.list)) {
                subObject.list = subObject.list.slice(0, 10);
            }
        }
    }
    return listData;
}

// 获取所有课程信息
export function getClassInfo() {
    const list = JSON.parse(JSON.stringify(listTitleData));
    return {
        result: list
    };
}

export function getClassInfoAndProcess() {
    const response = getClassInfo();
    const processedList = processList(response.result);
    return processedList;
}

// 根据 id 查询详情
export function getClassDetailsById(id) {
    return new Promise((resolve) => {
        const idNum = Number(id);
        if (isNaN(idNum)) {
            resolve({ result: null });
            return;
        }
        console.log("id:", id);
        const detail = indexMap.get(idNum) || null;
        resolve({ result: detail });
    });
}




// 根据 分类id 查询详情list
export function getClassCategoriesDetailsListById(id) {
    return new Promise((resolve) => {
        const idNum = Number(id);
        if (isNaN(idNum)) {
            resolve({ result: null });
            return;
        }
        const categories = Object.values(listTitleData);
        let detail = null;

        for (const category of categories) {
            if(idNum === category.id){
                detail = category
            }
        }
        resolve({ result: detail });
    });
}